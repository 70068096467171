<template>
  <div class="dashboard">
    <div class="nav-bar">
      <div class="menu-items">
        <div v-if="user" @click="navigateTo('user-dashboard')" class="menu-item">
          Home
        </div>
        <div @click="navigateTo('manage-classes')" class="menu-item">Quản lý lớp</div>
        <div @click="navigateTo('manage-quizzes')" class="menu-item">
          Quản lý đề kiểm tra
        </div>
        <div @click="navigateTo('manage-results')" class="menu-item">
          Quản lý kết quả
        </div>
        <div @click="navigateTo('guidelines')" class="menu-item">Hướng dẫn sử dụng</div>
      </div>

      <div class="user-section" v-if="user">
        <span class="user-name">{{ getFirstName(user.displayName) }}</span>
        <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
      </div>
    </div>
    <div class="page-title"> Trang quản lý kết quả các lần kiểm tra
    </div>
    <div class="result-content">
      <div classes="results-list">
        <table class="results-table">
          <thead>
            <tr>
              <th class="col-submitted-at">Thời gian</th>
              <th class="col-title">Danh sách</th>
              <th class="col-quiz-name">Tên bài kiểm tra</th>
              <th class="col-classroom-name">Lớp học</th>
              <th class="col-download">Tải về</th>
              <th class="col-delete">Xóa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in resultsList" :key="result.id">
              <td>{{ formatDate(result.submittedAt) }}</td>
              <td>{{ result.title }}</td>
              <td>
                <a href="#" @click.prevent="openLiveViewPreviewInNewTab(result.quizID)">
                  {{ getQuizNameFromTitle(result.title) }}
                </a>
              </td>
              <td>
                <a href="#" @click.prevent="openClassInNewTab(result.classID)">
                  {{ getClassNameFromTitle(result.title) }}
                </a>
              </td>
              <td><button @click="downloadResult(result.fileUrl)">Tải về</button></td>
              <td><button @click="deleteResult(result.id, result.fileUrl)">Xóa</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script src="./ResultsList.js"></script>
<style src="./ResultsList.css" scoped></style>
