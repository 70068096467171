import { db, auth } from "@/firebase.js";
import { collection, addDoc, setDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            user: null,
            studentsInput: "",
            firstName: '',
            middleName: '',
            lastName: '',

            studentsData: [],

            classroomData: {
                id: '',
                title: '',
                students: [],
                lastEdited: null,
                ownerUID: ''
            }
        };
    },

    async mounted() {
        const currentUser = auth.currentUser;
        if (currentUser) {
            this.user = currentUser;
        }
    },

    watch: {
        studentsInput() {
            this.handleNameInput();
        },
    },

    computed: {
        allFirstNames() {
            return this.studentsData.map(student => student.firstName).join('\n');
        },
        allMiddlelNames() {
            return this.studentsData.map(student => student.middleName).join('\n');
        },
        allLastNames() {
            return this.studentsData.map(student => student.lastName).join('\n');
        }
    },

    methods: {

        handleNameInput() {
            this.studentsData = this.parseStudentsInput(this.studentsInput);
        },

        parseStudentsInput(input) {
            let students = input.split('\n'); // Tách input thành mảng các dòng
            return students
                .filter(student => student.trim() !== '') // Loại bỏ dòng rỗng
                .map((student, index) => {
                    let names = student.trim().split(/\s+/); // Tách mỗi dòng thành mảng các từ
                    let firstName, middleName, lastName;

                    if (names.length > 2) {
                        firstName = names[0];
                        lastName = names[names.length - 1];
                        middleName = names.slice(1, names.length - 1).join(' ');
                    } else if (names.length === 2) {
                        firstName = names[0];
                        lastName = names[1];
                        middleName = '';
                    } else {
                        firstName = student.trim();
                        middleName = '';
                        lastName = '';
                    }
                    return { orderNum: String(index + 1), name: student.trim(), firstName, middleName, lastName };
                });
        },


        submitClass() {
            this.classroomData.students = this.parseStudentsInput(this.studentsInput);
            this.saveStudentsToFirestore(this.classroomData);
        },

        async saveStudentsToFirestore(classroomData) {
            try {
                const auth = getAuth(); // Lấy instance của Firebase Auth
                const currentUser = auth.currentUser; // Lấy người dùng hiện tại

                if (currentUser) {
                    classroomData.ownerUID = currentUser.uid;
                    classroomData.lastEdited = serverTimestamp();

                    const colRef = collection(db, 'classes');
                    const docRef = await addDoc(colRef, classroomData);

                    const autoId = docRef.id
                    classroomData.id = autoId;
                    await setDoc(docRef, classroomData);

                    Swal.fire({
                        title: 'Thông báo!',
                        text: 'Đã tạo danh sách lớp thành công.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });

                    this.$router.push('/manage-classes');
                } else {
                    console.error("User is not logged in. Cannot save the class.");
                }
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        },

        // Phần 2. Bổ sung cho Nav-bar

        onDashBoard() {
            this.$router.push('/user-dashboard')
        },

        navigateTo(routeName) {
            switch (routeName) {
                case 'user-dashboard':
                    this.$router.push("/user-dashboard");
                    break;
                case 'manage-classes':
                    this.$router.push("/manage-classes");
                    break;
                case 'manage-quizzes':
                    this.$router.push("/manage-quizzes");
                    break;
                case 'manage-results':
                    this.$router.push("/manage-results");
                    break;
                case 'guidelines':
                    this.$router.push("/guidelines");
                    break;
                case 'contact':
                    this.$router.push("/contact");
                    break;
            }
        },

        getFirstName(fullName) {
            if (!fullName) return '';
            return fullName.split(' ')[0];
        },
    }
};
