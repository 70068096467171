<template>
  <div class="dashboard">
    <div class="nav-bar">
      <div class="menu-items">
        <div v-if="user" @click="navigateTo('user-dashboard')" class="menu-item">
          Home
        </div>
        <div @click="navigateTo('manage-classes')" class="menu-item">Quản lý lớp</div>
        <div @click="navigateTo('manage-quizzes')" class="menu-item">
          Quản lý đề kiểm tra
        </div>
        <div @click="navigateTo('manage-results')" class="menu-item">
            Quản lý kết quả
          </div>
      </div>

      <div class="user-section" v-if="user">
        <span class="user-name">{{ getFirstName(user.displayName) }}</span>
        <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
      </div>
    </div>

    <div class="classroom-content">
      <div class="classroom-create">
        <button class="create-button" @click="createNewClassroom">Tạo mới</button>
      </div>

      <div classes="quizzes-list">
        <table class="classes-table">
          <thead>
            <tr>
              <th class="col-title">Danh sách lớp</th>
              <th class="col-students">Số lượng học sinh</th>
              <th class="col-last-edited">Sửa lần cuối</th>
              <th class="col-edit">Sửa</th>
              <th class="col-delete">Xóa</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="classroom in classes" :key="classroom.id">
              <td>
                <a href="#" @click.prevent="openClassInNewTab(classroom.id)">
                  {{ classroom.title }}
                </a>
              </td>
              <td>{{ classroom.students.length }}</td>
              <td>{{ formatDate(classroom.lastEdited) }}</td>
              <td><button @click="editClassInNewTab(classroom.id)">Edit</button></td>
              <td><button @click="deleteClassroom(classroom.id)">Delete</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script src="./ClassesList.js"></script>
<style src="./ClassesList.css" scoped></style>
