<template>
  <div class="loading-spinner-container">
    <div class="loading-spinner">
      <div class="countdown">{{ countDown }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countDown: 5, // Giá trị ban đầu
    };
  },
  created() {
    // Sử dụng setInterval để giảm giá trị của countDown sau mỗi giây
    const intervalId = setInterval(() => {
      if (this.countDown > 0) {
        this.countDown--;
      } else {
        clearInterval(intervalId); // Dừng đếm ngược khi countDown đạt 0
      }
    }, 1000); // Mỗi giây

    
  },


};
</script>

<style>
.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Chiều cao 100% của màn hình */
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  position: relative;
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 180px;
  color: #0077cc;
}

.loading-spinner:after {
  content: "";
  display: block;
  width: 320px;
  height: 320px;
  margin: 8px;
  border-radius: 50%;
  border: 30px solid #ff6600; /* Viền màu cam */
  border-color: transparent #ff6600 transparent #ff6600;
  animation: loading-spinner 1.2s linear infinite;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
