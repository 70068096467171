import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';  // import store
import { auth } from "@/firebase.js";

import '@fortawesome/fontawesome-free/css/all.css';

import 'tinymce/themes/silver'; // Import theme của TinyMCE
import 'tinymce/skins/ui/oxide/skin.min.css'; // Import CSS cho skin



// Tạo một instance của app
const app = createApp(App);

// Sử dụng router và store
app.use(router);
app.use(store);

// Mount app
app.mount('#app');



auth.onAuthStateChanged(user => {
    if (user) {
        // console.log(user.uid);
      // Người dùng đã đăng nhập
      store.dispatch('listenToLiveQuizUpdates', user.uid);
    } else {
      // Người dùng chưa đăng nhập hoặc đã đăng xuất
      router.push('/user-login');
    }
  });
  