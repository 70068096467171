import { createStore } from 'vuex';
import { db } from "@/firebase.js";
import { doc, onSnapshot } from 'firebase/firestore';
import router from '@/router';

let unsubscribe;


export default createStore({
  state: {
    liveQuizId: null,
    currentQuestionIndex: 0
  },
  mutations: {
    SET_LIVE_QUIZ_ID(state, id) {
      state.liveQuizId = id;
    },
    SET_CURRENT_QUESTION_INDEX(state, index) {
      state.currentQuestionIndex = index;
    },
    SET_SHOW_CHOICES_AND_RESULTS(state, showChoicesAndResults) {
      state.showChoicesAndResults = showChoicesAndResults;
    },
    SET_CLASS_ID(state, classID) {
      state.classID = classID;
    }

  },
  actions: {
    setLiveQuizId({ commit }, id) {
      commit('SET_LIVE_QUIZ_ID', id);
    },

    listenToLiveQuizUpdates({ commit }, userUID) {
      const docRef = doc(db, 'currentQuizStatus', userUID);
      let hasRedirected = false; // Biến để kiểm tra đã chuyển trang hay chưa
      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists) {
          const statusData = doc.data();
          
          if (statusData && statusData.status === "idle") {
            hasRedirected = false; // Đánh dấu chưa chuyển trang đồng hồ
          }

          if (statusData && statusData.status === "live" && statusData.quizId) {
            // Chuyển đến trang đồng hồ đếm ngược
            if (!hasRedirected) {
              router.push('/waiting-clock');
              hasRedirected = true; // Đánh dấu đã chuyển trang đồng hồ
              // Sau 5 giây, chuyển đến trang Quiz-Live-View
              setTimeout(() => {
                router.push(`/quiz-live-view/${statusData.quizId}`);
              }, 5000);
            } else {
              // Đang ở trang Quiz-Live-View thì chuyển thẳng
              router.push(`/quiz-live-view/${statusData.quizId}`);
            }

            // Cập nhật vị trí câu hỏi sử dụng mutation
            commit('SET_CURRENT_QUESTION_INDEX', statusData.currentQuestionIndex);

            // Cập nhật biến hiển thị kết quả phương án trả lời
            commit('SET_SHOW_CHOICES_AND_RESULTS', statusData.showChoicesAndResults);

            // Cập nhật biến chọn lớp học
            commit('SET_CLASS_ID', statusData.classID);

          } else if (statusData && statusData.status === "idle") {
            console.log(statusData.status);

            router.push('/user-dashboard');
            // console.log(statusData.status);
          }
        } else {
          console.log("No such document in currentQuizStatus!");
        }
      });
    },


    stopListeningToLiveQuizUpdates() {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }
});
