<template>
  <div class="login">
    <div class="login-container">
      <h2>Đăng nhập với Google</h2>
      <p>Đăng nhập bằng cách sử dụng địa chỉ gmail và mật khẩu gmail</p>
      <button @click="signInWithGoogle" class="login-button">
        <img src="@/assets/icons8-google-96.png" alt="Google Icon" class="google-icon" />
        Đăng nhập
      </button>
    </div>
  </div>
</template>

<script>
import { auth, googleAuthProvider } from "@/firebase.js";
import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";

export default {
  name: "UserLogin",
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // Người dùng đã đăng nhập
        this.$router.push("/user-dashboard");
      }
    });
  },
  methods: {
    async signInWithGoogle() {
      try {
        // Đặt chế độ duy trì đăng nhập trước khi thực hiện đăng nhập
        await setPersistence(auth, browserLocalPersistence);

        const result = await signInWithPopup(auth, googleAuthProvider);
        const user = result.user;
        if (user) {
          await this.saveUserToFirestore(user);
          this.$nextTick(() => {
            this.$router.push("/user-dashboard");
          });
        }
      } catch (error) {
        console.error("Lỗi đăng nhập:", error);
      }
    },
    async saveUserToFirestore(user) {
      const userRef = doc(getFirestore(), "users", user.uid);
      const userSnapshot = await getDoc(userRef);

      if (!userSnapshot.exists()) {
        const { uid, displayName, email, photoURL } = user;
        try {
          await setDoc(userRef, {
            uid,
            displayName,
            email,
            photoURL,
            // bạn cũng có thể thêm các trường khác mà bạn muốn lưu
          });
        } catch (error) {
          console.error("Lỗi lưu người dùng:", error);
        }
      }
    },
  },
};
</script>

<style scoped src="./UserLogin.css">  </style>
