<template>
  <div class="dashboard">
    <div class="nav-bar">
      <div class="menu-items">
        <div v-if="user" @click="navigateTo('user-dashboard')" class="menu-item">
          Home
        </div>
        <div @click="navigateTo('manage-classes')" class="menu-item">Quản lý lớp</div>
        <div @click="navigateTo('manage-quizzes')" class="menu-item">
          Quản lý đề kiểm tra
        </div>
        <div @click="navigateTo('manage-results')" class="menu-item">
            Quản lý kết quả
          </div>
      </div>

      <div class="user-section" v-if="user">
        <span class="user-name">{{ getFirstName(user.displayName) }}</span>
        <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
      </div>
    </div>
    <div class="classroom-create">
      <div class="classroom-title">
        <label for="classroom-input">Tên Lớp: </label>
        <input
          placeholder="Nhập tên lớp tại đây."
          type="text"
          class="classroom-input"
          v-model="classroomData.title"
          required
        />
      </div>
      <div class="classroom-content">
        <div id="student-fullname1">
          <label for="student-text1">Nhập họ và tên</label>
          <textarea
            id="student-text1"
            v-model="studentsInput"
            required
            rows="30"
          ></textarea>
        </div>
        <div id="student-fullname2">
          <label for="student-text1">Họ</label>
          <textarea
            id="student-text1"
            v-model="allFirstNames"
            rows="30"
            readonly
          ></textarea>
        </div>
        <div id="student-fullname2">
          <label for="student-text1">Tên lót</label>
          <textarea
            id="student-text1"
            v-model="allMiddlelNames"
            rows="30"
            readonly
          ></textarea>
        </div>
        <div id="student-fullname2">
          <label for="student-text1">Tên</label>
          <textarea
            id="student-text1"
            v-model="allLastNames"
            rows="30"
            readonly
          ></textarea>
        </div>

      </div>
    </div>

    <div id="button-container">
      <button type="button" @click="submitClass">Tạo lớp học</button>
    </div>
  </div>
</template>

<script src="./ClassCreateNew.js"></script>
<style scoped src="./ClassCreateNew.css"></style>
