<template>
  <div class="page-back">
    <div class="dashboard">
      <div class="nav-bar">
        <div class="menu-items">
          <div v-if="user" @click="navigateTo('user-dashboard')" class="menu-item">
            Home
          </div>
          <div @click="navigateTo('manage-classes')" class="menu-item">Quản lý lớp</div>
          <div @click="navigateTo('manage-quizzes')" class="menu-item">
            Quản lý đề kiểm tra
          </div>
          <div @click="navigateTo('manage-results')" class="menu-item">
            Quản lý kết quả
          </div>
          <div @click="navigateTo('contact')" class="menu-item">Liên hệ</div>

          <div class="user-section" @click="toggleMenu" v-if="user">
            <span class="user-name">{{ getFirstName(user.displayName) }}</span>
            <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
            <div v-if="isMenuOpen" class="popover-menu" @mousedown.stop="">
              <div class="menu-item" v-if="user">
                <span class="user-name">{{ user.displayName }}</span>
                <img :src="user.photoURL" alt="User Avatar" class="user-avatar" />
              </div>
              <div @click="navigateTo('account')" class="menu-item">Tài khoản</div>
              <div @click="navigateTo('guidelines')" class="menu-item">Hướng dẫn</div>
              <div @click="navigateTo('contact')" class="menu-item">Liên hệ</div>
              <div @click="logout" class="menu-item">Đăng xuất</div>
            </div>
          </div>
        </div>
      </div>

      <div class="body-content">
        <div class="cards-container">
          <div @click="navigateTo('manage-quizzes')" class="card">
            <div class="icon-container">
              <img src="@/assets/icons8-quiz-100.png" alt="Quiz Icon" class="card-icon" />
            </div>
            <div class="card-header">Danh sách đề kiểm tra</div>
            <div class="card-body">
              <p class="card-content">
                Trang lưu giữ danh sách các bài kiểm tra của bạn.
              </p>
            </div>
          </div>
          <div @click="navigateTo('manage-classes')" class="card">
            <div class="icon-container">
              <img
                src="@/assets/icons8-classroom-100.png"
                alt="Quiz Icon"
                class="card-icon"
              />
            </div>
            <div class="card-header">Danh sách lớp</div>
            <div class="card-body">
              <p class="card-content">
                Trang lưu giữ danh sách học sinh các lớp học của bạn.
              </p>
            </div>
          </div>
          <div @click="navigateTo('app-download')" class="card">
            <div class="icon-container">
              <img
                src="@/assets/icons8-download-app-100.png"
                alt="Quiz Icon"
                class="card-icon"
              />
            </div>
            <div class="card-header">Tải App về điện thoại</div>
            <div class="card-body">
              <p class="card-content">
                Hiển thị link để tải app cho điện thoại Android và Iphone.
              </p>
            </div>
          </div>
        </div>
        <div class="cards-container">
          <div @click="navigateTo('card-download')" class="card">
            <div class="icon-container">
              <img
                src="@/assets/icons8-plicker-100.png"
                alt="Quiz Icon"
                class="card-icon"
              />
            </div>
            <div class="card-header">Tải thẻ trả lời</div>
            <div class="card-body">
              <p class="card-content">Trang tải PDF để in THẺ trả lời cho học sinh.</p>
            </div>
          </div>
          <div @click="navigateTo('manage-results')" class="card">
            <div class="icon-container">
              <img
                src="@/assets/icons8-result-100.png"
                alt="Quiz Icon"
                class="card-icon"
              />
            </div>
            <div class="card-header">Danh sách Kết quả</div>
            <div class="card-body">
              <p class="card-content">
                Trang quản lý danh sách kết quả các lần kiểm tra.
              </p>
            </div>
          </div>
          <div @click="navigateTo('guidelines')" class="card">
            <div>
              <img
                class="card-preview-web-app"
                src="@/assets/img-app-web.png"
                alt="Quiz Icon"
              />
              <div class="card-header">Hướng dẫn</div>
            </div>
          </div>
        </div>
      </div>

      <footer class="site-footer">
        <a href="http://hotro.bondapan.vn/privacy_policy">Privacy and Policy</a>
        <a href="http://hotro.bondapan.vn/blog">Bản quyền @bondapan</a>
        <a href="http://hotro.bondapan.vn/lien-he">Liên hệ</a>
      </footer>
    </div>
  </div>
</template>

<script src="./UserDashboard.js"></script>

<style scoped src="./UserDashboard.css"></style>
