import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '@/components/Login/UserLogin.vue';
import UserDashboard from '@/components/user_dashboard/UserDashboard.vue';
import ClassesList from '@/components/ClassManagement/ClassesList.vue';
import QuizzesList from '@/components/QuizManagement/QuizzesList.vue';
import ResultsList from '@/components/ResultsManagement/ResultsList.vue';
import QuizCreate from '@/components/QuizCreate/QuizCreate.vue';
import WaitingClock from '@/components/WaitingClock/WaitingClock.vue';
import ClassCreate from '@/components/ClassCreateNew/ClassCreateNew.vue';


import { auth } from "@/firebase.js";

const routes = [
  {
    path: '/',
    redirect: '/user-login'
  },

  {
    path: '/user-login',
    name: 'user-login',
    component: UserLogin
  },
  {
    path: '/user-dashboard',
    name: 'user-dashboard',
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/waiting-clock',
    name: 'waiting-clock',
    component: WaitingClock,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/manage-classes',
    component: ClassesList,
    name: 'manage-classes',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/manage-results',
    component: ResultsList,
    name: 'manage-results',
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/class-detail/:id',
    name: 'class-detail',
    component: () => import('@/components/ClassDetail/ClassDetail1.vue'),
    props: true
  },

  {
    path: '/class-create',
    name: 'class-create',
    component: ClassCreate,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/edit-class/:id',
    name: 'edit-class',
    component: () => import('@/components/EditClassroomNew/EditClassroomNew.vue'),
    props: true,
  },

  {
    path: '/manage-quizzes',
    component: QuizzesList,
    name: 'manage-quizzes',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create-quiz',
    name: 'create-quiz',
    component: QuizCreate,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/edit-quiz/:id',
    name: 'EditQuiz',
    component: () => import('@/components/EditQuiz/EditQuiz.vue'),
  },


  {
    path: '/quiz-live-view/:id',
    name: 'quiz-live-view',
    component: () => import('@/components/QuizLiveView/QuizLiveView.vue')
  },
  
  {
    path: '/quiz-live-view-preview/:id',
    name: 'quiz-live-view-preview',
    component: () => import('@/components/QuizLiveViewNew/QuizLiveViewNew.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      user => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}


router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = await getCurrentUser();
 
  if (requiresAuth && !currentUser) {
    next('/user-login');
  } else if (to.path === '/user-login' && currentUser) {
    next('/user-dashboard');
  } else {
    next();
  }
});

export default router;
