import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDpsQOttXGoWkfDO7kia2MiWiGlYA7NRhY",
  authDomain: "chamthi-card-scan.firebaseapp.com",
  projectId: "chamthi-card-scan",
  storageBucket: "chamthi-card-scan.appspot.com",
  messagingSenderId: "625268188687",
  appId: "1:625268188687:web:05891bb154fb00bb16e57a",
  measurementId: "G-04ST5MF9HW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

export { db, auth, googleAuthProvider, signInWithPopup, storage };