import { db, auth } from '@/firebase.js';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from "firebase/storage";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      resultsList: [],
      currentUserUID: null,
      previewResult: null,
      user: null,
      className: "",
      quizName: "",
      selectedMenuItem: null,
    };
  },

  async mounted() {

    // Hiển thị thông tin người dùng
    const currentUser = auth.currentUser;
    if (currentUser) {
      this.user = currentUser;
    }

    // Hiển thị thông tin người dùng
    this.currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

    if (this.currentUserUID) {
      try {
        const q = query(collection(db, 'allResults'), where('ownerUID', '==', this.currentUserUID), orderBy('submittedAt', 'desc'));
        const querySnapshot = await getDocs(q);
        console.log("ResultsList fetched from Firestore:", querySnapshot.docs);
        this.resultsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error("Error fetching ResultsList:", error);
      }
    } else {
      console.error("User is not authenticated");
    }
  },

  methods: {
    formatDate(timestamp) {
      const date = timestamp.toDate();
      const formatted = new Intl.DateTimeFormat('vi-VN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).format(date);
      return `${formatted.slice(0, 8)}, ${formatted.slice(8)}`;
    },


    deleteResult(resultId, fileUrl) {

      // Xác nhận trước khi xóa
      Swal.fire({
        title: 'Xác nhận!',
        text: 'Bạn có chắc chắn muốn xóa Kết quả kiểm tra này?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Xóa document từ Firestore
            const resultRef = doc(db, 'allResults', resultId);
            await deleteDoc(resultRef);

            // Phân tích URL để lấy tên file
            const decodedUrl = decodeURIComponent(fileUrl);
            const pathRegex = /o\/(.+)\?alt/;
            const match = decodedUrl.match(pathRegex);
            const filePath = match ? match[1] : null;

            if (!filePath) {
              throw new Error('Không thể xác định đường dẫn file từ URL');
            }

            // Xóa file từ Storage
            const storage = getStorage();
            const fileRef = ref(storage, filePath);
            await deleteObject(fileRef);

            // Xóa kết quả khỏi danh sách hiển thị
            this.resultsList = this.resultsList.filter(result => result.id !== resultId);

            console.log('Document and file deleted successfully');
          } catch (error) {
            console.error('Error deleting document or file:', error);
            Swal.fire('Lỗi', 'Không thể xóa kết quả và file!', 'error');
          }
        }
      });
    },

    downloadResult(fileUrl) {
      window.open(fileUrl, '_blank');
    },

    getFirstName(fullName) {
      if (!fullName) return '';
      return fullName.split(' ')[0];
    },

    getClassNameFromTitle(str) {
      return str.split('-')[1];
    },

    getQuizNameFromTitle(str) {
      return str.split('-')[0];
    },

    openClassInNewTab(classroomId) {
      const url = `/class-detail/${classroomId}`;
      this.$router.push(url);
    },

    openLiveViewPreviewInNewTab(quizId) {
      const url = `/quiz-live-view-preview/${quizId}`;
      this.$router.push(url);
    },

    // Phần 2. Bổ sung cho Nav-bar

    onDashBoard() {
      this.$router.push('/user-dashboard')
    },

    navigateTo(routeName) {
      switch (routeName) {

        case 'user-dashboard':
          this.$router.push("/user-dashboard");

          break;
        case 'manage-classes':
          this.$router.push("/manage-classes");
          break;
        case 'manage-quizzes':
          this.$router.push("/manage-quizzes");
          break;
        case 'manage-results':
          this.$router.push("/manage-results");
          break;
        case 'guidelines':
          this.$router.push("/guidelines");
          break;
        case 'contact':
          this.$router.push("/contact");
          break;
      }
    },

  }
}
