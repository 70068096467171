import { db, auth } from '@/firebase.js';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { doc, deleteDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

export default {

  data() {
    return {
      quizzes: [],
      currentUserUID: null,
      previewQuiz: null,
      user: null  // Thêm dòng này
    };
  },


  async mounted() {

    // Hiển thị thông tin người dùng

    const currentUser = auth.currentUser;
    if (currentUser) {
      this.user = currentUser;
    }

    // Hiển thị thông tin người dùng

    this.currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

    if (this.currentUserUID) {
      try {
        const q = query(collection(db, 'quizzes'), where('ownerUID', '==', this.currentUserUID), orderBy('lastEdited', 'desc'));
        const querySnapshot = await getDocs(q);
        this.quizzes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    } else {
      console.error("User is not authenticated");
    }
  },


  methods: {
    formatDate(timestamp) {
      const date = timestamp.toDate();
      const formatted = new Intl.DateTimeFormat('vi-VN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).format(date);
      return `${formatted.slice(0, 8)}, ${formatted.slice(8)}`;
    },

    showPreview(quiz) {
      this.previewQuiz = quiz;
    },

    writeNewQuiz() {
      this.$router.push('/quiz-writer'); // đường dẫn đến trang tạo bài kiểm tra là '/create-quiz'
    },

    createNewQuiz() {
      this.$router.push('/create-quiz'); // đường dẫn đến trang tạo bài kiểm tra là '/create-quiz'
    },

    async deleteQuiz1(quizId) {
      // Xác nhận trước khi xóa
      if (window.confirm('Bạn có chắc chắn muốn xóa bài kiểm tra này?')) {
        try {
          const quizRef = doc(db, 'quizzes', quizId);
          await deleteDoc(quizRef);

          // Xóa bài kiểm tra ra khỏi danh sách trên giao diện
          this.quizzes = this.quizzes.filter(quiz => quiz.id !== quizId);
          alert('Đã xóa bài kiểm tra thành công!');
        } catch (error) {
          console.error('Lỗi xóa bài kiểm tra:', error);
          alert('Có lỗi xảy ra khi xóa bài kiểm tra.');
        }
      }
    },


    async deleteQuiz(quizId) {
      // Xác nhận trước khi xóa
      Swal.fire({
        title: 'Xác nhận!',
        text: 'Bạn có chắc chắn muốn xóa bài kiểm tra này?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const quizRef = doc(db, 'quizzes', quizId);
          deleteDoc(quizRef);

          // Xóa bài kiểm tra ra khỏi danh sách trên giao diện
          this.quizzes = this.quizzes.filter(quiz => quiz.id !== quizId);
        } else if (result.isDismissed) {
          // Người dùng nhấp vào "Cancel" hoặc nhấp ra ngoài thông báo
          // Không làm gì cả hoặc thực hiện hành động khác nếu cần thiết
        }
      });
    },

    editQuizInNewTab(quizId) {
      const url = `/edit-quiz/${quizId}`;
      this.$router.push(url);
    },


    openLiveViewInNewTab(quizId) {
      const url = `/quiz-live-view/${quizId}`;
      this.$router.push(url);
    },

    openLiveViewPreviewInNewTab(quizId) {
      const url = `/quiz-live-view-preview/${quizId}`;
      this.$router.push(url);
    },

    // Phần 2. Bổ sung cho Nav-bar

    onDashBoard() {
      this.$router.push('/user-dashboard')
    },

    navigateTo(routeName) {
      switch (routeName) {
        case 'user-dashboard':
          this.$router.push("/user-dashboard");
          break;
        case 'manage-classes':
          this.$router.push("/manage-classes");
          break;
        case 'manage-quizzes':
          this.$router.push("/manage-quizzes");
          break;
        case 'manage-results':
          this.$router.push("/manage-results");
          break;

        case 'guidelines':
          this.$router.push("/guidelines");
          break;
        case 'contact':
          this.$router.push("/contact");
          break;
      }
    },

    getFirstName(fullName) {
      if (!fullName) return '';
      return fullName.split(' ')[0];
    },

  }
}
