import { auth } from "@/firebase.js";  // Đảm bảo rằng bạn đã export firebase từ file firebase.js

export default {
  data() {
    return {
      isMenuOpen: false,
      menuClicked: false,
      user: null,
    };
  },
  mounted() {
    const currentUser = auth.currentUser;
    if (currentUser) {
      this.user = currentUser;
    }

    window.addEventListener('mousedown', this.handleClickOutside);
  },

  beforeUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  },

  methods: {

    onDashBoard() {
      this.$router.push('/user-dashboard')
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (!this.isMenuOpen) {
        this.menuClicked = true;
      }
    },

    navigateTo(routeName) {
      switch (routeName) {
        case 'manage-classes':
          this.$router.push("/manage-classes");
          break;
        case 'manage-quizzes':
          this.$router.push("/manage-quizzes");
          break;
          
        case 'app-download':
          window.open("http://hotro.bondapan.vn/app-download", '_blank');
          break;
        case 'card-download':
          window.open("http://hotro.bondapan.vn/card-download", '_blank');
          break;
        case 'guidelines':
          window.open("http://hotro.bondapan.vn", '_blank');
          break;
        case 'contact':
          window.open("http://hotro.bondapan.vn/lien-he", '_blank');
          break;
        case 'manage-results':
            this.$router.push("/manage-results");
            break;
        case 'letters':
          window.open("http://hotro.bondapan.vn/blog", '_blank');
          break;
      }
    },
    logout() {
      auth.signOut()
        .then(() => {
          console.log('Đăng xuất thành công');
          this.isMenuOpen = false;
          this.$router.push('/user-login');
        })
        .catch(error => {
          console.error('Đăng xuất thất bại:', error);
        });
    },


    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.isMenuOpen) {
        this.isMenuOpen = false;
      }
      this.menuClicked = false;  // Đặt lại cờ sau khi xử lý
    },


    getFirstName(fullName) {
      if (!fullName) return '';
      return fullName.split(' ')[0];
    },

  }
};

