import { db, auth } from '@/firebase.js';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { doc, deleteDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      classes: [],
      currentUserUID: null,
      previewClassroom: null,
      user: null  // Thêm dòng này
    };
  },

  async mounted() {

    // Hiển thị thông tin người dùng

    const currentUser = auth.currentUser;
    if (currentUser) {
      this.user = currentUser;
    }

    // Hiển thị thông tin người dùng
    this.currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

    if (this.currentUserUID) {
      try {
        const q = query(collection(db, 'classes'), where('ownerUID', '==', this.currentUserUID), orderBy('lastEdited', 'desc'));
        const querySnapshot = await getDocs(q);
        console.log("Classes fetched from Firestore:", querySnapshot.docs);
        this.classes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    } else {
      console.error("User is not authenticated");
    }
  },

  methods: {
    formatDate(timestamp) {
      const date = timestamp.toDate();
      const formatted = new Intl.DateTimeFormat('vi-VN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).format(date);
      return `${formatted.slice(0, 8)}, ${formatted.slice(8)}`;
    },

    showPreview(classroom) {
      this.previewClassroom = classroom;
    },

    createNewClassroom() {
      this.$router.push('/class-create'); // đường dẫn đến trang tạo bài kiểm tra là '/create-class'
    },


    deleteClassroom(classroomId) {

      // Xác nhận trước khi xóa
      Swal.fire({
        title: 'Xác nhận!',
        text: 'Bạn có chắc chắn muốn xóa Danh sách lớp này?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const classroomRef = doc(db, 'classes', classroomId);
          deleteDoc(classroomRef);

          // Xóa bài kiểm tra ra khỏi danh sách trên giao diện
          this.classes = this.classes.filter(classroom => classroom.id !== classroomId);
        } else if (result.isDismissed) {
          // Người dùng nhấp vào "Cancel" hoặc nhấp ra ngoài thông báo
          // Không làm gì cả hoặc thực hiện hành động khác nếu cần thiết
        }
      });
    },

    openClassInNewTab(classroomId) {
      const url = `/class-detail/${classroomId}`;
      this.$router.push(url);
    },

    editClassInNewTab(classroomId) {
      const url = `/edit-class/${classroomId}`;
      this.$router.push(url);
    },


    openLiveViewInNewTab(classroomId) {
      const url = `/live-view/${classroomId}`;
      this.$router.push(url);
    },


    // Phần 2. Bổ sung cho Nav-bar

    onDashBoard() {
      this.$router.push('/user-dashboard')
    },

    navigateTo(routeName) {
      switch (routeName) {
        case 'user-dashboard':
          this.$router.push("/user-dashboard");
          break;
        case 'manage-classes':
          this.$router.push("/manage-classes");
          break;
        case 'manage-quizzes':
          this.$router.push("/manage-quizzes");
          break;
        case 'guidelines':
          this.$router.push("/guidelines");
          break;
        case 'manage-results':
          this.$router.push("/manage-results");
          break;
        case 'contact':
          this.$router.push("/contact");
          break;
      }
    },

    getFirstName(fullName) {
      if (!fullName) return '';
      return fullName.split(' ')[0];
    },

  }
}
